import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import './styles/featuredSpeakers.scss';


const FeaturedSpeakers = () => {
    return (
        <StaticQuery 
            query={graphql`
                query FeaturedSpeakersQuery {
                    allFeaturedSpeakersJson {
                        nodes {
                            name
                            company
                            url
                            headshot {
                                childImageSharp {
                                    gatsbyImageData(layout:CONSTRAINED)
                                }
                            }
                        }
                    }
                }  
            `}
            render={data => (
                <div className="featuredSpeakers blocksection" id="featured-speakers">
                    <div className="container">
                        <h2>Previous Keynote & Featured Speakers</h2>
                    </div>
                    <div className="container">
                        {data.allFeaturedSpeakersJson.nodes.map(m => (
                            <article className="speaker" key={m.name.toString()}>
                                <a href={m.url.toString()}>
                                    <div className="img-wrapper">
                                        <GatsbyImage alt={m.name} image={m.headshot.childImageSharp.gatsbyImageData} /></div>
                                    <div className="txt-wrapper">
                                        <span>{m.name}</span>
                                        <div className="company">{m.company}</div>
                                    </div>
                                </a>
                            </article>
                        ))}
                        <article className="speaker empty" key="empty">
                            <Link to="/past-events">
                                <div className="txt-wrapper">
                                    <span>Previous Summits →</span>
                                </div>
                            </Link>
                        </article>
                    </div>
                </div>
            )}
        
        />
    )
}


export default FeaturedSpeakers